import React, { Component } from 'react';
import './App.css';
import Fisa from './Pages/Fisa';
import { Route } from 'react-router-dom';
import Home from './Pages/Home';
import Search from './Pages/Search';
import Login from './Pages/Login';
import Resurse from './Pages/Resurse';
import JSON5 from 'json5';
import { error } from 'jquery';

function parseJSONManually(jsonString) {
  try {
    // Extract the array of results from the JSON string
    const resultsMatch = jsonString.match(/\[.*\]/s);
    if (!resultsMatch) {
      throw new Error('No results array found in the JSON string.');
    }

    const resultsArray = JSON.parse(resultsMatch[0]);
    
    // Convert idChapter values to integers
    const parsedResults = resultsArray.map(result => {
      if (result.idChapter !== undefined) {
        result.idChapter = parseInt(result.idChapter);
      }
      return result;
    });

    return parsedResults;
  } catch (error) {
    console.error('Error parsing JSON manually:', error);
    return null;
  }
}

class App extends Component {

  constructor(props){
    super(props);
    //dev = https://dbviewer.dev.yellowgnu.net/
   //let test = "https://dbviewer.dev.yellowgnu.net";
    // Use proxy in package.json for local
    let local = "https://dbviewer.matrixrom.ro"
    //let local = "http://127.0.0.1:4000"
    window.API_URL = local;
    window.debug = false;
    var old_lg = console.log;
    console.log = function(...args){
        if(window.debug)
           old_lg.apply(this,args);
    }

  }

 static tryJSONParse(arg){
    try{
      return JSON.parse(arg);
    }catch(e){
      console.log(e);
      return {};
    }
  }


  componentDidMount() {

var user = localStorage.user?App.tryJSONParse(localStorage.user):undefined;
console.log(user);
if(user && user.token && window.location.href.indexOf("/Login")<0){
  fetch(window.API_URL+'/getTree?userId='+user.id+"&token="+user.token).then((response) => {
      response.json().then((rsp) => {
        console.log(rsp, "initial");
        window.editions = rsp.available;
        //window.tobuy = Object.values(rsp.to_buy?rsp.to_buy:{});
        //window.tobuy = rsp.to_buy;
        window.locuinte = rsp.locuinte;
        window.alte = rsp.alte;
        window.payloadBooks = rsp.payloadBooks;
        window.booksNotOwnedInstal = rsp.booksNotOwnedInstal;
        window.booksNotOwnedConstr = rsp.booksNotOwnedConstr;

        this.forceUpdate();
        window.app = this;
      });
    })
  }else if(window.location.href.indexOf("/Login")<0){
    window.location.href = "/Login";
  }

  window.useResults = (search_results_raw) => {
    if(typeof search_results_raw == 'undefined' ){
      window.results = [];
      this.forceUpdate();
    } else {
      search_results_raw.json().then((search_results) => {

        window.results = search_results.length > 0 ? search_results : [];
        this.forceUpdate();
      })
    }
  }
}





  render() {

    return (
      <>
        <Route exact path="/Login" component={Login} /> 
        <Route exact path="/Fisa" component={Fisa} />
        <Route exact path="/" component={Home} />
        <Route exact path="/search" component={Search} />
        <Route exact path="/Resurse" component={Resurse} />
      </>
    );
  }

}



export default App;
