import React from 'react';
import '../Home.css';
import App from '../App';

import FunctionalComponents from '../FunctionalComponents';

class Home extends React.Component{

  
      
      render() {
        var FCP = new FunctionalComponents();
        window.editions  = window.editions? window.editions:[];
        window.results  = window.results? window.results:[];
        window.resultBooks  = window.resultBooks? window.resultBooks:[];
        
        
        setTimeout(function(){
            if(localStorage.user){
              var user = App.tryJSONParse(localStorage['user']);
              try{
                window.gtag('event', 'activitate_aplicatie',{'User-ID':user.user_id});
                window.fbq('trackCustom','activitate_aplicatie',{'User-ID':user.user_id});

              }catch(e){console.warn(e);}
            }
        },3000);
        return <>
        <FCP.navbar />
        <FCP.sidebar editions={window.editions} opened={true} />
        <FCP.search_header />
        <FCP.results results={window.results} />
        </>
      }
        

      
  
}

export default Home;

