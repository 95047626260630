import React, {useEffect, useState} from "react";
import App from "../App.css";
import Cookies from "universal-cookie";
import { hashing } from "./utils/hash";
import Form from "./components/Form";
import ForgotPassword from "./components/ForgotPassword";
import SuccessRegistration from "./components/SuccessRegistration";

import FunctionalComponents from "../FunctionalComponents";

function toggleElements(firstElement, secondElement) {
	if (firstElement.classList.contains("hidden")) {
		firstElement.classList.remove("hidden");
		secondElement.classList.add("hidden");
		return;
	}

	firstElement.classList.add("hidden");
	secondElement.classList.remove("hidden");
}

class Login extends React.Component {
	
	
	constructor(props) {
		super();
		if (window.token) {
			props.history.push("/");
		}
		this.state = {
			login: false,
			successRegistration: false,
			forgotPassword: false,
			csrfToken: null,
		};
		this.attemptLogin = this.attemptLogin.bind(this);
		this.attemptRegister = this.attemptRegister.bind(this);
		this.handleSetAction = this.handleSetAction.bind(this);
		this.forgotPassword = this.forgotPassword.bind(this);
		this.resetPassword = this.resetPassword.bind(this);
		
	}

	componentDidMount() {
        this.setState({login: true});
		// fetch(window.API_URL +'/csrf-token')
		// 	.then(response => response.json())
		// 	.then(data => {
		// 		this.setState({csrfToken: data.csrf_token}); 
		// 	})
		// 	.catch(error => {
		// 		console.error('Error fetching CSRF token:', error);
		// 	});
 
          
	}

	attemptLogin(event) {
		
		var hash = 0;
		var target = document.getElementById("password").value;
		if (target.length === 0) {
		} else {
			hash = hashing(target);
		}
		const cookies = new Cookies();

		var payload = {
			email: document.getElementById("email").value,
			password: hash + 70296187,
			uid: cookies.get("uid"),
		};

		const loginButton = event.target;
		const spinner = document.getElementById("spinner");

		const csrfToken = this.state.csrfToken;

		toggleElements(loginButton, spinner);

		fetch(window.API_URL + "/login", {
			method: "POST",
			headers: {
				Accept: "application/json",
				'X-CSRF-TOKEN': csrfToken
			},
			body: JSON.stringify(payload),
		}).then((response) => {
			toggleElements(loginButton, spinner);
			response.json().then((rsp) => {
				if (rsp.token) {
					window.token = rsp.token;
					console.log(rsp);
					localStorage["user"] = JSON.stringify({
						token: rsp.token,
						id: rsp.user_id,
						uid: rsp.uniqueId,
						email: payload.email,
					});

					if (!cookies.get("uid"))
						cookies.set("uid", rsp.uniqueId, {
							path: "/",
							maxAge: Math.pow(2, 32) - 1,
						});

					try {
						window.gtag("event", "login", { "User-ID": rsp.user_id });
						window.fbq("trackCustom", "login", { "User-ID": rsp.user_id });
					} catch (e) {
						console.warn(e);
					}
					window.location.href = "/";
				} else if (rsp.response == "failed") {
					document.getElementById("warn").classList.add("hidden");
					document.getElementById("warn-devs").classList.remove("hidden");
				} else {
					document.getElementById("warn-devs").classList.add("hidden");
					document.getElementById("warn").classList.remove("hidden");
				}
			});
		}).finally(document.getElementById("password").value = "")
	} 

	attemptRegister(event) {
		//to do : validate input data

		var hash = 0;
		var target = document.getElementById("password").value;
		var passwordConfirm = document.getElementById("passwordRe").value;

		if (target.length < 6 || target !== passwordConfirm) {
			alert("Parola trebuie sa contina minim 6 caractere.");
			window.location.reload();
		} else {
			// console.log('target: ' + target);
			hash = hashing(target);
		}

		var payload = {
			email: document.getElementById("email").value,
			password: hash + 70296187,
			passwordConfirm: passwordConfirm,
			uid: null,
		};

		fetch(window.API_URL + "/register", {
			method: "POST",
			headers: {
				// 'Content-Type': 'application/json',
				Accept: "application/json",
			},
			body: JSON.stringify(payload),
		}).then((response) => {
			response
				.json()
				.then((res) => {
					if (res.success === true) {
						this.setState({ successRegistration: true });
					} else {
						alert(
							`A aparut o eroare la inregistrare. Daca problema persista, contactati-ne!`
						);
						window.location.reload();
					}
				})
				.catch((e) => {
					alert(
						"A aparut o eroare la inregistrare. Daca problema persista, contactati-ne! (erroare cod 500)"
					);
					window.location.reload();
				});
		});
	}

	handleSetAction(event) {
		event.preventDefault();
		this.setState({
			login: !this.state.login,
		});
	}

	forgotPassword(event) {
		event.preventDefault();
		this.setState({
			forgotPassword: !this.state.forgotPassword,
		});
	}

	resetPassword(event) {
		var payload = {
			email: document.getElementById("email").value,
		};
		if (document.getElementById("email").value == 0) {
			alert("Va rugam introduceti-va email-ul");
		} else {
			const resetButton = event.target;
			const spinner = document.getElementById("spinner");

			toggleElements(resetButton, spinner);

			fetch(window.API_URL + "/resetPassword", {
				method: "POST",
				headers: {
					Accept: "application/json",
				},
				body: JSON.stringify(payload),
			}).then((response) => {
				toggleElements(resetButton, spinner);
				if (!response.ok) {
					document.getElementById("resetPassword-error").classList.remove('hidden')
					return;
				}
				response.json().then((res) => {
					document.getElementById("resetPassword").classList.remove("hidden");
				});
			}).finally(document.getElementById("email").value = "")
		}
	}

	clearNotifications() {
		const warn = document.getElementById("warn");
		const resetPassword = document.getElementById("resetPassword");
		const resetPasswordError = document.getElementById("resetPassword-error");

		if (warn) {
			warn.classList.add("hidden");
		}
		if (resetPassword) {
			resetPassword.classList.add("hidden");
		}
		if (resetPasswordError) {
			resetPasswordError.classList.add("hidden");
		}
	}

	//console.log(doc.getElementsByTagName('input')[0].value); //"token: "+container.contentDocument.getElementsByTagName('input')[0].value

	render() {
		document.body.style.paddingTop = "0";
		return (
			<>   
				<div className="bg-img-overlay">
					<div className="overlay"></div>
					<img src="assets/img/spalsh3.png" />
				</div>
				<div className="rel zindex5">
					<div className="container">
						<div className="row">
							<div className="col-sm-12">
								<div id="login-logo">
									<p className="title-logo">Preturi pentru Evaluari</p>
								</div>
							</div>
						</div>

						<div className="row">
							<div className="col-lg-6 col-lg-offset-3 col-md-8 col-md-offset-2 col-sm-8 col-sm-offset-2">
								<div className="login-form form">
									{(() => {
										if (this.state.forgotPassword) {
											return (
												<ForgotPassword
													onClick={
														this.state.login
															? this.attemptLogin
															: this.attemptRegister
													}
													onFocus={this.clearNotifications}
													handleSetAction={this.sendResetLink}
													forgotPassword={this.forgotPassword}
													sendResetLink={this.resetPassword}
													principalButton={"RESETEAZA PAROLA"}
													resetButton={"INAPOI LA LOGIN"}
												/>
											);
										} else if (this.state.successRegistration) {
											return <SuccessRegistration />;
										} else {
											return (
												<Form
													onClick={
														this.state.login
															? this.attemptLogin
															: this.attemptRegister
													}
													onFocus={this.clearNotifications}
													handleSetAction={this.handleSetAction}
													forgotPassword={this.forgotPassword}
													principalButton={
														this.state.login ? "LOGIN" : "CREEAZA CONT"
													}
													resetButton={
														this.state.login
															? "RESETEAZA PAROLA"
															: "RESETEAZA PAROLA"
													}
													secondaryButton={
														this.state.login ? "DEMO" : "INAPOI LA LOGIN"
													}
													retypePassword={this.state.login ? "none" : "block"}
													displayRegMsg={this.state.login ? "none" : "block"}
												/>
											);
										}
									})()}
								</div>
							</div>
						</div>

						<div className="row logo-row">
							<div className="col-sm-12">
								<div id="login-logo">
									<img
										src="assets/img/matrixrom.png"
										className="img-responsive center-block"
									/>
								</div>
							</div>
						</div>
					</div>
				</div>
			</>
		);
	}
}
/*
 <div className="cat-loading">
				<div className="cat-frame"></div>
				<h3 className="loading-text">loading ...</h3>
				<img className="loading-logo" src="assets/img/logo.svg"/>
</div>

  <script type="text/javascript" src="framework/js/jquery-2.1.0.min.js"></script>
								<script type="text/javascript" src="framework/js/bootstrap.min.js"></script>
								<script type="text/javascript" src="framework/owl/dist/owl.carousel.min.js"></script>
								<script type="text/javascript" src="framework/js/framework.js"></script>
								<script type="text/javascript" src="assets/js/custom.js"></script>

*/

export default Login;
