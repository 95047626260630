import React from 'react'
import '../App.css'
import FunctionalComponents from '../FunctionalComponents'
import App from './../App'
import printJS from 'print-js'
import { ScrollMenu } from 'react-horizontal-scrolling-menu';
import ReactLoading from "react-loading";
import { usePromiseTracker, trackPromise } from "react-promise-tracker";

var resizebase64 = require('resize-base64');

const LoadingIndicator = props => {
  const { promiseInProgress } = usePromiseTracker();

  return ( promiseInProgress &&
    <div display="flex" justify-content="center">
        <ReactLoading type="spinningBubbles" color="#112D4E" />
    </div> 
);  
}

class Fisa extends React.Component {

  constructor(props) {
    super()

    this.state = { data: '', lines: [] }
    document.getElementsByTagName('html')[0].style.minWidth = '100%'
    document.getElementsByTagName('html')[0].style.width = 'max-content'
  }

  componentDidMount() {
    function watermarkedDataURL(canvas) {
      var tempCanvas = document.createElement('canvas')
      var tempCtx = tempCanvas.getContext('2d')
      var cw, ch
      cw = tempCanvas.width = canvas.width
      ch = tempCanvas.height = canvas.height

      var img = new Image()
      img.src = 'assets/img/matrixrom.png'
      tempCanvas.width = 2 * img.height
      tempCanvas.width = 2 * img.width
      tempCtx.filter = 'brightness(10) grayscale(1) opacity(0.35)'
      //         tempCtx.globalAlpha = .50;

      tempCtx.rotate((-45 * Math.PI) / 180)
      tempCtx.translate(img.height, img.width)
      tempCtx.drawImage(img, -img.width, -img.height, 300, 100)

      // just testing by adding tempCanvas to document

      return tempCanvas.toDataURL()
    }

    var payload = {
      id: this.props.location.query ? this.props.location.query.id : -1,
      userId: App.tryJSONParse(localStorage['user']).id,
      token: App.tryJSONParse(localStorage['user']).token,
    }

    console.log(localStorage)

    if (payload.id === undefined || payload.id <= 0) {
      this.props.history.push('/')
    }

    if (localStorage.user) {
      var user = App.tryJSONParse(localStorage['user'])
      try {
        window.gtag('event', 'accesare_fisa', {
          'User-ID': user.user_id,
          Fisa: payload.id,
        })
        window.fbq('trackCustom', 'accesare_fisa', {
          'User-ID': user.user_id,
          Fisa: payload.id,
        })
      } catch (e) {
        console.warn(e)
      }

      setTimeout(
        function () {
          try {
            window.gtag('event', 'fisa_citita', {
              'User-ID': user.user_id,
              Fisa: payload.id,
            })
            window.fbq('trackCustom', 'fisa_citita', {
              'User-ID': user.user_id,
              Fisa: payload.id,
            })
          } catch (e) {
            console.warn(e)
          }
        }.bind(user, payload),
        30 * 100,
      )

      // console.error('payload: ')
      // console.error(payload);
      trackPromise(
      fetch(window.API_URL + '/open', {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'text/plain',
        },

        body: JSON.stringify(payload),
      })).then((response) => {
        response.json().then((jsn) => {
          var c = document.getElementById('myCanvas')
          // if(jsn.width_factor <= 4) c.width = '1300'
          // else c.width = '1800'
          if(jsn.width_factor && jsn.width_factor > 4) c.width = '1800'
          else if(jsn.width_factor && jsn.width_factor <= 4) c.width = '1300'
          else c.width = '1200'
          c.height = 300 + 35 * jsn.height_factor
          var ctx = c.getContext('2d')
          var img = new Image()
          var img2 = new Image()
          img.crossOrigin = 'anonymous'
          img.onload = () => {
            //ctx.drawImage(img2, c.width / 2 - 280, c.height / 2 - 200)
            ctx.drawImage(img, 15, 10)
            console.error("image loaded succesfully")
          }

          var dataURL = watermarkedDataURL(c)

          //console.error("aici e json conntent: ", jsn.content);
          //img2.src = dataURL
          img.src = jsn.content

          // img.onerror = () => {
          //   console.error('Image failed to load:', img.src);
          // };
          
          // img2.onerror = () => {
          //   console.error('Image2 failed to load:', img2.src);
          // };
          

          document.getElementById('printButt').onclick = function () {
            /*   document.querySelector('style').textContent += "@media print{  * { visibility: visible; } #myCanvas {position: absolute; top: 0;left: 0;visibility: visibile; }}"
                       window.print();  */

            fetch(window.API_URL + '/print', {
              method: 'POST',
              headers: {
                // 'Content-Type': 'application/json',
                Accept: 'application/json',
              },
              body: JSON.stringify(payload),
            }).then((response) => {
              response.json().then((res) => {
                var prints = res.prints
                console.log(prints)
                if (prints > 0) {
                  console.log('res;')

                  if (res.success === true) {
                    var resizedImage = c.toDataURL()
                    var title = document.getElementById("title").innerHTML
                    printJS({printable: resizedImage, type: 'image', imageStyle: 'width: auto; height: auto; display:flex;justify-content:center;text-align:center;', documentTitle:title })
                  } else {
                    alert(`A aparut o eroare la printare`)
                  }
                } else {
                  alert(
                    `Cota de printare a fost epuizata! Puteti achizitiona dreputuri suplimentare de printare de pe site-ul https://www.matrixrom.ro/`,
                  )
                  window.open('https://matrixrom.ro/', '_blank')
                }
              })
            })
          }
          //Event dupa printare, dar nu spune daca a printat pagina sau nu, doar daca a deschis o in the first place
          window.addEventListener('afterprint', (event) => {
            console.log('After print')
          })
          this.forceUpdate()
        })
      })
    }
  }

  componentWillUnmount() {
    document.getElementsByTagName('html')[0].style.minWidth = '100%'
    document.getElementsByTagName('html')[0].style.width = 'auto'
  }

  render() {
    var FCP = new FunctionalComponents()
    return (
      <>
        <FCP.navbar locked={true} hidden={true} />
        <FCP.sidebar editions={window.editions} opened={false} />
        <FCP.search_header
          backbutton={true}
          edts={['Editia Aprilie 2020', 'Editia Iunie 2020']}
          construction_types={['Constructii Uzuale', 'Alte Constructii']}
        />

        

        <div className="App">
          <div style={{
                  alignItems: 'center',
                  display: 'flex',
                  justifyContent: 'center',
            }}>
            <h2 style={{width: "80%"}} id="title">
              {this.props.location.query ? this.props.location.query.editie : ''}
            </h2>

            <button
              className="btn btn-default"
              id="printButt"
              style={{
                float: 'right',
                marginTop: '10px',
                border: '1px solid black',
                background: 'white',
              }}
            >
              {' '}
              <i className="fa fa-print"></i> Print{' '}
            </button>
          </div>

          <div style={{display:"flex", justifyContent:"center", alignItems:"center"}}>
            <LoadingIndicator/>
          </div>

          <div id="canvas-wrapper">
            <p align="center"
              display="flex"
              justify-content="center">

                <ScrollMenu>
                  <canvas
                  onContextMenu={(e) => e.preventDefault()}
                  id="myCanvas"
                  height="1500px"
                  className='scrollable-content'
                  ></canvas>
                </ScrollMenu>
              
            </p>
          </div>
        </div>
      </>
    )
  }
}

export default Fisa
