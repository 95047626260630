export  function hashing(pass) {

    var hash = 0, i, chr;

    for (i = 0; i < pass.length; i++) {
        chr = pass.charCodeAt(i);
        hash = ((hash << 5) - hash) + chr;
        hash |= 0; // Convert to 32bit integer
    }

    return hash;
}

