import React, { useEffect, useState, forceUpdate } from 'react';
import { useHistory } from "react-router-dom";
import {
    Link
} from "react-router-dom";

import App from './App';
import LoadingIndicator from './Pages/LoadingIndicator';
import { trackPromise } from "react-promise-tracker";
import { usePromiseTracker } from 'react-promise-tracker';

class FunctionalComponent {


    sidebar(props) {
        const [edts, seteditions] = useState(window.editions);
        const [payloadBooks, setPayloadBooks] = useState(window.payloadBooks);
        const [booksNotOwnedInstal, setBooksNotOwnedInstal] = useState(window.booksNotOwnedInstal);
        const [booksNotOwnedConstr, setBooksNotOwnedConstr] = useState(window.booksNotOwnedConstr);
        const [locuinte, setLocuinte] = useState(window.locuinte);
        const [alte, setAlte] = useState(window.alte);
        localStorage.setItem("constructii", []);
        localStorage.setItem("instalatii", []);
        localStorage.setItem("Alte Tipuri de Cladiri", []);
        localStorage.setItem("Cladiri de locuit", []);

        
        useEffect(() => {
            seteditions(window.editions);

        }, [props, edts])

        useEffect(() => {
            setPayloadBooks(window.payloadBooks);

        }, [props, payloadBooks])

        useEffect(() => {
            setBooksNotOwnedInstal(window.booksNotOwnedInstal);

        }, [props, booksNotOwnedInstal])

        useEffect(() => {
            setBooksNotOwnedConstr(window.booksNotOwnedConstr);

        }, [props, booksNotOwnedConstr])

        useEffect(() => {
            setLocuinte(window.locuinte);
        }, [props, locuinte])

        useEffect(() => {
            setAlte(window.alte);
        }, [props, alte])

        

        function OpenBook(event) {
            window.useResults();

            var overlay = document.getElementById('leftMenuOverlay');
            var leftMenu = document.getElementById('left-menu');

            if (overlay.classList.contains('opened')) {
                leftMenu.classList.remove('opened');
                overlay.classList.remove('opened');
            }
            var href = event.target.getAttribute('query');
            var name = event.target.getAttribute('name');
            var payload = {
                qr: '-',
                sku: href,
                token: App.tryJSONParse(localStorage.user).token,
                userId:App.tryJSONParse(localStorage.user).id,
            }

            localStorage.setItem("origine", "buletine");
            localStorage.setItem("editie", name.split(" ")[2] + " " + name.split(" ")[3]);
            if(payload.sku[1] == 'i') localStorage.setItem("tip", "instalatii");
            else localStorage.setItem("tip", "constructii");


            if (localStorage.user) {
                // var user = App.tryJSONParse(localStorage['user']);
                // try {
                //     window.gtag('event', 'accesare_editie',
                //         {
                //             'User-ID': user.user_id,
                //             'editie': href.split('&')[0],
                //             'categorie': href.split('&')[1]
                //         }
                //     );
                //     window.fbq('trackCustom', 'accesare_editie', {
                //         'User-ID': user.user_id,
                //         'editie': href.split('&')[0],
                //         'categorie': href.split('&')[1]
                //     })
                // } catch (e) { console.warn(e); }

                trackPromise(fetch(window.API_URL + '/getbookscontent', {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'text/plain',
                    },
                    body: JSON.stringify(payload)
                })).then(window.useResults);
            }
        }

        function NodeBook(props) {
            var value = props.value;
            //value = value.charAt(0).toUpperCase() + value.slice(1).toLowerCase()
            var href = props.href;
            var children = props.children;
            var valuesArray = []
            
            console.log(props);
            children = children === undefined ? [] : children;
            if (href) {
                return <a query={href} onClick={OpenBook} className={"avType " + value} name={value}>
                    {value}
                </a>
            } else {
                return <>
                    <a className={"avBookEdition " + value} data-toggle="collapse" name={value} href={"#" + value.replace(" ", "").replace(" ", "").replace(" ", "")} aria-expanded="true" style={{textTransform: "capitalize"}}>
                        <span className="ico"></span>{value + " (" + children.length + ")" }
                        {
                            children.length > 0 ? <i className="fa fa-caret-down" aria-hidden="true"></i> : null
                        }
                        
                    </a>
                    <div className="collapse" id={value.replace(" ", "").replace(" ", "").replace(" ", "")} aria-expanded="true" >
                        <ul>
                            {  
                                children.map((value, index) => {
                                    var element = value.element.value.split(" ");
                                    if(valuesArray[element[1].toLowerCase()] == null)
                                    {
                                        valuesArray[element[1].toLowerCase()] = [element[2] + " " + element[3]];
                                    }
                                    else valuesArray[element[1].toLowerCase()].push(element[2] + " " + element[3]);

                                    localStorage.setItem(element[1].toLowerCase(), valuesArray[element[1].toLowerCase()]);


                                    var new_val = value.element === undefined ? undefined : value.element.value;
                                    var new_href = value.element === undefined ? undefined : value.element.href;
                                    var sube = value.subelements === undefined ? [] : value.subelements;
                                    var customkey = Math.floor(Math.random() * 100000);
                                    return <ul key={customkey}><NodeBook value={new_val} href={new_href} children={sube} /></ul>
                                })
                            }
                        </ul>
                    </div>
                </>
            }
        }

        function Books(props) {
            /* PATTERN 
             <li>
                            <a data-toggle="collapse" href="#features" className="" aria-expanded="true">  <!-- id must be unique and must match with collapsible div -->
                    <span className="ico"></span>Editii<i className="fa fa-caret-down" aria-hidden="true"></i>
                            </a>
                            <div className="collapse in" id="features" aria-expanded="true" >
                                <ul>
             */
            var elements = props.elements && props.elements.map ? props.elements : [];

            return <> {
                elements.map((value, index) => {

                    var node = value.element
                    var children = value.subelements
                    var key = Math.floor(Math.random() * 100000);
                    window.key = key
                    

                    //node.isLeaf, node.value, node.href
                    if (node.href !== undefined) {

                        return <li key={window.key} ><NodeBook value={node.value} href={node.href} /></li>
                    } else {
                        return <li key={window.key}> <NodeBook value={node.value} href={node.href} children={children} /> </li>
                    }

                })

            }
            </>


        }



        function OpenEdition(event) {
            console.log(event, event.target, event.target.attribute);
            var overlay = document.getElementById('leftMenuOverlay');
            var leftMenu = document.getElementById('left-menu');
            

            if (overlay.classList.contains('opened')) {
                leftMenu.classList.remove('opened');
                overlay.classList.remove('opened');
            }
            var href = event.target.getAttribute('query');
            var name = event.target.getAttribute('name');
            var payload = {
                qr: '-',
                editie: href.split('&')[0],
                categorie: href.split('&')[1],
                token: App.tryJSONParse(localStorage.user).token,
            }

            localStorage.setItem('categorie', payload.categorie);
            localStorage.setItem("editie", name);
            localStorage.setItem("origine", "indreptare");


            if (localStorage.user) {
                var user = App.tryJSONParse(localStorage['user']);
                try {
                    window.gtag('event', 'accesare_editie',
                        {
                            'User-ID': user.user_id,
                            'editie': href.split('&')[0],
                            'categorie': href.split('&')[1]
                        }
                    );
                    window.fbq('trackCustom', 'accesare_editie', {
                        'User-ID': user.user_id,
                        'editie': href.split('&')[0],
                        'categorie': href.split('&')[1]
                    })
                } catch (e) { console.warn(e); }

                trackPromise(fetch(window.API_URL + '/getcontent', {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'text/plain',
                    },
                    body: JSON.stringify(payload)
                })).then(window.useResults);
            }
        }


        function Profile(props) {
            return <>
                <div className="user-info">
                    <div className="img">
                        <img alt="" style={{ filter: "brightness(10) grayscale(1)", transform: "scale(0.9) translateX(-15px)" }} src="assets/img/matrixrom.png" />
                    </div>
                    <div className="info">
                        <p className="title" style={{ textAlign: "left" }}>{props.name}</p>
                        <p className="subtitle">{props.email}</p>
                    </div>
                </div> </>
        }

        function Node(props) {
            var value = props.value;
            var href = props.href;
            var children = props.children;
            var valuesArray = new Array();

            children = children === undefined ? [] : children;

            if(value != null && value !== "")
            {
                console.error("value: ",  value);
                if (href) {
                    return <a query={href} onClick={OpenEdition} className={"avCategories " + value} name={value}>
                        <span className="ico"></span> {value}
                    </a>
                } else {
                    return <>
                        <a className={"avEdition " + value} data-toggle="collapse" name={value} href={"#" + value.replace(" ", "").replace(" ", "").replace(" ", "")} aria-expanded="true">
                        <span className="ico" ></span>{value + " (" + children.length + ")"}
                            {
                                children.length > 0 ? <i className="fa fa-caret-down" aria-hidden="true"></i> : null
                            }
                        </a>
                        <div className="collapse" id={value.replace(" ", "").replace(" ", "").replace(" ", "")} aria-expanded="true" >
                            <ul>
                                {
                                    children.map((value, index) => {
                                        if(valuesArray[value.element.href.split('&')[1]] == null)
                                            valuesArray[value.element.href.split('&')[1]] = new Array(value.element.value);
                                        else valuesArray[value.element.href.split('&')[1]].push(value.element.value);
                                        localStorage.setItem(value.element.href.split('&')[1], valuesArray[value.element.href.split('&')[1]]);
                                        var new_val = value.element === undefined ? undefined : value.element.value;
                                        var new_href = value.element === undefined ? undefined : value.element.href;
                                        var sube = value.subelements === undefined ? [] : value.subelements;
                                        var customkey = Math.floor(Math.random() * 100000);
                                        return <li key={customkey}><Node value={new_val} href={new_href} children={sube} /></li>
                                    } )
    
                                    
                                }
                            </ul>
                        </div>
                    </>
                }
            }

            else return <></>
            
        }

        function onClickLogOut() {
            localStorage.removeItem('user');
            window.location.href = "/Login";
        }

        function Editions(props) {

            var elements = props.elements && props.elements.map ? props.elements : [];

            return <> {
                elements.map((value, index) => {

                    var node = value.element
                    var children = value.subelements
                    var key = Math.floor(Math.random() * 100000);
                    window.key = key
                    

                    //node.isLeaf, node.value, node.href
                    if (node.href !== undefined) {

                        return <li key={window.key} ><Node value={node.value} href={node.href} /></li>
                    } else {
                        return <li key={window.key}> <Node value={node.value} href={node.href} children={children} /> </li>
                    }

                })

            }
            </>


        }



        return <>
            <div id="left-menu" className={props.opened ? "drawer-menu left opened" : "drawer-menu left"}>
                <div className="drawer-banner">
                    <img alt="" className="img-responsive" src="assets/img/spalsh2.png" />
                    <div className="overlay"></div>
                    <Profile name={""} email={JSON.parse(localStorage.user ? localStorage.user : "{}").email} />
                </div>
                <div className="content">
                    <ul>

                        <li>
                            <div className="divider"></div>
                        </li>

                        <li>
                            <a data-toggle="collapse" href="#features" className="" aria-expanded="true">
                                <span className="ico"></span>Continutul Meu<i className="fa fa-caret-down" aria-hidden="true"></i>
                            </a>

                            <div id="features">
                                <ul>
                                    <a data-toggle="collapse" href="#indreptare" className="" aria-expanded="true">
                                        <span className="ico"></span>Indreptare de preturi<i className="fa fa-caret-down" aria-hidden="true"></i>
                                    </a>
                                    <div className="collapse in" id="indreptare" aria-expanded="true" >
                                        <ul>
                                            <Editions elements={edts} />
                                        </ul>
                                    </div>
                                </ul>

                                <ul>
                                    <a data-toggle="collapse" href="#buletine" className="" aria-expanded="true">
                                        <span className="ico"></span>Buletine de preturi<i className="fa fa-caret-down" aria-hidden="true"></i>
                                    </a>
                                    <div className="collapse in" id="buletine" aria-expanded="true" >

                                    <ul>
                                        <Books elements={payloadBooks} />
                                    </ul>

                                        
                                    </div>
                                </ul> 


                                
                            </div>

                        </li>

                        <li>
                            <div className="divider"></div>
                        </li>

                        <li>
                            <a data-toggle="collapse" href="#alteeditii" className="" aria-expanded="true">
                                <span className="ico"></span>Continut Disponibil<i className="fa fa-caret-down" aria-hidden="true"></i>
                            </a>
                            <div id="alteeditii">
                            <ul>
                                    <a data-toggle="collapse" href="#indreptareNotOwned" className="" aria-expanded="true">
                                        <span className="ico"></span>Indreptare de preturi<i className="fa fa-caret-down" aria-hidden="true"></i>
                                    </a>
                                    <div className="collapse" id="indreptareNotOwned" aria-expanded="true" >

                                    <ul>
                                            <a data-toggle="collapse" href="#locuinteNotOwned" className="" aria-expanded="true">
                                                <span className="ico"></span>Cladiri de Locuinte<i className="fa fa-caret-down" aria-hidden="true"></i>
                                            </a>
                                            <div className="collapse" id="locuinteNotOwned" aria-expanded="true" >
                                                <ul>
                                                    {(() => {
                                                        if (window.locuinte && window.locuinte.length > 0) {
                                                            return window.locuinte.map(function (carte) {
                                                                return <ul key={carte.id}>
                                                                    <a onClick={() => {window.open(carte.link, "_blank") }} className="" style={{ textTransform: 'capitalize' }} >
                                                                        {carte.content}
                                                                    </a>
                                                                </ul>

                                                            })
                                                        }

                                                        else return null;


                                                    })()}
                                                </ul>
                                            </div>
                                        </ul>
                                        
                                        <ul>
                                            <a data-toggle="collapse" href="#alteNotOwned" className="" aria-expanded="true">
                                                <span className="ico"></span>Alte Tipuri de Cladiri<i className="fa fa-caret-down" aria-hidden="true"></i>
                                            </a>
                                            <div className="collapse" id="alteNotOwned" aria-expanded="true" >
                                                <ul>
                                                    {(() => {
                                                        if (window.alte && window.alte.length > 0) {
                                                            return window.alte.map(function (carte) {
                                                                return <ul key={carte.id}>
                                                                    <a onClick={() => { window.open("https://www.matrixrom.ro/produs/indreptar-tehnic-pentru-evaluare-elemente-si-constructii-industriale-si-social-culturale/", "_blank") }} className="" style={{ textTransform: 'capitalize' }} >
                                                                        {carte.content}
                                                                    </a>
                                                                </ul>

                                                            })
                                                        }

                                                        else return null;


                                                    })()}
                                                </ul>
                                            </div>
                                        </ul>

                                        
                                    </div>
                                </ul> 

                                <ul>
                                    <a data-toggle="collapse" href="#buletineNotOwned" className="" aria-expanded="true">
                                        <span className="ico"></span>Buletine de preturi<i className="fa fa-caret-down" aria-hidden="true"></i>
                                    </a>
                                    <div className="collapse" id="buletineNotOwned" aria-expanded="true" >

                                    <ul>
                                            <a data-toggle="collapse" href="#constructiiNotOwned" className="" aria-expanded="true">
                                                <span className="ico"></span>Constructii<i className="fa fa-caret-down" aria-hidden="true"></i>
                                            </a>
                                            <div className="collapse" id="constructiiNotOwned" aria-expanded="true" >
                                                <ul>
                                                    {(() => {
                                                        if (window.booksNotOwnedConstr && window.booksNotOwnedConstr.length > 0) {
                                                            return window.booksNotOwnedConstr.map(function (carte) {
                                                                return <ul key={carte.idContent}>
                                                                    <a onClick={() => { window.open(carte.Link, "_blank") }} className="" style={{ textTransform: 'capitalize' }} >
                                                                        {carte.ContentName}
                                                                    </a>
                                                                </ul>

                                                            })
                                                        }

                                                        else return null;

                                                    })()}
                                                </ul>
                                            </div>
                                        </ul>
                                        
                                        <ul>
                                            <a data-toggle="collapse" href="#instalatiiNotOwned" className="" aria-expanded="true">
                                                <span className="ico"></span>Instalatii<i className="fa fa-caret-down" aria-hidden="true"></i>
                                            </a>
                                            <div className="collapse" id="instalatiiNotOwned" aria-expanded="true" >
                                                <ul>
                                                    {(() => {
                                                        if (window.booksNotOwnedInstal && window.booksNotOwnedInstal.length > 0) {
                                                            return window.booksNotOwnedInstal.map(function (carte) {
                                                                return <ul key={carte.idContent}>
                                                                    <a onClick={() => {window.open(carte.Link, "_blank") }} className="" style={{ textTransform: 'capitalize' }} >
                                                                        {carte.ContentName}
                                                                    </a>
                                                                </ul>

                                                            })
                                                        }

                                                        else return null;


                                                    })()}
                                                </ul>
                                            </div>
                                        </ul>

                                        
                                    </div>
                                </ul>
                            </div>
                        </li>

                        <li>
                            <div className="divider"></div>
                        </li>
                        
                        <div style={{display:"flex", justifyContent:"center", alignItems:"center", marginTop:"50%"}}>
                            <LoadingIndicator/>
                        </div>
                        
                    </ul>
                </div>
                
                <div className="logOut">
                    <ul>
                        <li><a onClick={onClickLogOut}><span className="ico"><i className="fa fa-user" aria-hidden="true" ></i></span>Log Out</a></li>
                        <li><a target="_blank" href="https://www.matrixrom.ro/produs/preturi-pentru-evaluari/"><span className="ico"><i className="fa fa-shopping-cart" aria-hidden="true" ></i></span>Cumpara</a></li>
                    </ul>
                </div>
            </div>
            <div id="leftMenuOverlay" onClick={window.toggleLeftSideMenu} className={props.opened ? "drawer overlay opened" : "drawer overlay"} data-reff="#left-menu"></div>
        </>
    }



    search_header(props) {
        const [arrNou, setArr2] = useState([]);

        var edition_titles = Array.from(document.getElementsByClassName('avEdition')).map(em => em.getAttribute('name')).filter(function (value, index, self) { return self.indexOf(value) === index; });

        var construction_types = Array.from(document.getElementsByClassName('avCategories')).map(em => em.getAttribute('name')).filter(function (value, index, self) { return self.indexOf(value) === index; });

        var buletine = Array.from(document.getElementsByClassName('avBookEdition')).map(em => em.getAttribute('name')).filter(function (value, index, self) { return self.indexOf(value) === index; });

        var tipuri = Array.from(document.getElementsByClassName('avType')).map(em => em.getAttribute('name')).filter(function (value, index, self) { return self.indexOf(value) === index; });


        var formattedArray = new Array();

        for(var i=0;i<tipuri.length;i++)
        {
            var word = tipuri[i].split(" ")[2] + " " + tipuri[i].split(" ")[3];
            formattedArray.push(word);
        }

        function handleCategoryChange (event, value) {

            const selectedCategory = event.target.value;
            localStorage.setItem(value, selectedCategory)
            
            //localStorage.setItem("editie", "all")
            if(selectedCategory !== "all" && localStorage.getItem(selectedCategory) != []) {var arr = localStorage.getItem(selectedCategory);}
            else {var arr = formattedArray;}
            setArr2(arr);

        };

        function BackButton(props) {
            const history = useHistory()
            var onbackclick = function () {
                history.goBack();
            }
            return <button className="btn btn-default" onClick={onbackclick} style={{ float: 'right', marginRight: '10px', marginTop: '10px', border: '1px solid black', background: 'white' }}> <i className="fa fa-chevron-left"></i> Inapoi </button>
        }

        const history = useHistory();

        function search_clicked(ev) {
            ev.preventDefault();
            var q = document.getElementById('search').value;

            if (!q && !document.getElementById('categorie') && !document.getElementById('editie')) {
                alert("Nicio valoare de cautat!");
                return;
            }

            if(document.getElementById('editie') !== null) localStorage.setItem("editie", document.getElementById('editie').value);
            if(document.getElementById('tip') !== null) localStorage.setItem("tip", document.getElementById('tip').value);
            if(document.getElementById('categorie') !== null) localStorage.setItem("categorie", document.getElementById('categorie').value);
            
            var payload = {
                qr: document.getElementById('search').value,
                editie: document.getElementById('editie').value,
                categorie: document.getElementById('categorie') !== null ? document.getElementById('categorie').value : null,
                tip: document.getElementById('tip') !== null ? document.getElementById('tip').value : null,
                token: App.tryJSONParse(localStorage.user).token,
                userId:App.tryJSONParse(localStorage.user).id,
            }

            if (localStorage['user']) {
                var user = App.tryJSONParse(localStorage['user']);
                try {
                    window.gtag('event', 'cautare_efectuata',
                        {
                            'User-ID': user.user_id,
                            'query': payload.qr,
                            'editie': payload.editie,
                            //'categorie': payload.categorie
                        }
                    );
                    window.fbq('trackCustom', 'cautare_efectuata', {
                        'User-ID': user.user_id,
                        'query': payload.qr,
                        'editie': payload.editie,
                        //'categorie': payload.categorie
                    })

                } catch (e) { console.warn(e); }

                history.push("/search");
                trackPromise(fetch(window.API_URL + '/search', {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'text/plain'
                    },
                    body: JSON.stringify(payload)
                })).then(window.useResults);
            }

        }

        

        var interval = setInterval(function () {
            if (document.getElementsByClassName('avEdition').length != 0 && edition_titles.length === 0) {
                console.log("set");

                edition_titles = Array.from(document.getElementsByClassName('avEdition')).map(em => em.getAttribute('name')).filter(function (value, index, self) { return self.indexOf(value) === index; });//props.edts ? props.edts : []; metoda filter ajuta la eliminarea duplicatelor din filtrele gasite
                construction_types = Array.from(document.getElementsByClassName('avCategories')).map(em => em.getAttribute('name')).filter(function (value, index, self) { return self.indexOf(value) === index; });
                try { window.app.forceUpdate() } catch (e) { }
            } else if (document.getElementsByClassName('avEdition').length == 0) {
                clearInterval(interval);
            }
        }, 500)

        if(localStorage.getItem("origine") === "indreptare")
        {
            var selectId1 = "categorie";
            var defaultCheck1 = "Toate cladirile";
            var arr1 = edition_titles;
            if(localStorage.getItem(localStorage.getItem(selectId1)) == null)
                {
                    if(localStorage.getItem(selectId1)==="all") var arr2 = construction_types;
                    else arr2 = [];
                }
            else 
                {
                    var str = localStorage.getItem(localStorage.getItem(selectId1));
                    if (str != null && str.includes(","))
                    {
                        arr2 = localStorage.getItem(localStorage.getItem(selectId1)).split(",");
                    }

                    else arr2 = [localStorage.getItem(localStorage.getItem(selectId1))]
                    
                }

        }

        else {
            var selectId1 = "tip";
            var defaultCheck1 = "Toate categoriile";
            var arr1 = buletine;
            if(localStorage.getItem(localStorage.getItem(selectId1)) == null)
                {
                    if(localStorage.getItem(selectId1)==="all") var arr2 = formattedArray;
                    else arr2 = [];
                }
            else 
                {
                    arr2 = localStorage.getItem(localStorage.getItem(selectId1)).split(",");
                }
        }

        arr1 = [...new Set(arr1)];
        arr2 = [...new Set(arr2)];


        return <>
            <div className="container-fluid fixed" style={{ top: "52px", width: "100%" }}>
                <div className="row" id="search-box">
                    <div className="col-sm-12" style={{ float: "none" }}>
                        <form className="cat-from" style={{ width: 'max-content', display: 'inline-block' }}>
                            <div className="form-group searchWrp" style={{ display: "inline-flex", width: "100%" }}>
                                <input type="text" id="search" name="search" className="form-control" placeholder="Cauta :" style={{ width: "30%" }} />
                                <span className="clear" style={{ position: 'relative', marginLeft: "10px",
    marginTop: "7px" }}><i className="fa fa-times-circle" onClick={() => { document.getElementById('search').value = ""; }} aria-hidden="true"></i></span>

                                <select id={selectId1} style={{ marginLeft: "50px", color: "black", background: "white" }} onChange={(event) => handleCategoryChange(event, selectId1)}>
                                    {localStorage.getItem(selectId1) === null ? null : <option defaultChecked value="all" >{defaultCheck1}</option>}                                 
                                    {
                                        arr1.map((value, index) => {
                                            return (value !== localStorage.getItem(selectId1) ?  <option key={Math.random()} value={value}>{value.charAt(0).toUpperCase() + value.slice(1).toLowerCase()}</option> : <option key={Math.random()} value={value} selected>{value.charAt(0).toUpperCase() + value.slice(1).toLowerCase()}</option>)
                                        })
                                    }
                                </select>

                                <select id="editie" style={{ marginLeft: "50px", color: "black", background: "white" }}>
                                {localStorage.getItem("editie") !== null ? <option defaultChecked value="all" >Toate editiile</option> : null} 
                                    {
                                        arr2.map((value, index) => {
                                            return (value !== localStorage.getItem("editie") ? <option key={Math.random()} value={value}>{value}</option> : <option key={Math.random()} value={value} selected>{value}</option>)

                                        })
                                    }

                                </select>
                                <button className="btn btn-default" style={{ marginLeft: '50px' }} onClick={search_clicked}>Cauta</button>
                            </div>
                        </form>
                        {(() => {
                            if (props.backbutton)
                                return <BackButton />

                        })()}

                    </div>
                </div>

            </div>
        </>
    }


    navbar(props) {

        window.toggleLeftSideMenu = function () {
            if (!props.locked) {
                var overlay = document.getElementById('leftMenuOverlay');
                var leftMenu = document.getElementById('left-menu');

                if (overlay.classList.contains('opened')) {
                    leftMenu.classList.remove('opened');
                    overlay.classList.remove('opened');
                } else {
                    overlay.classList.add('opened');
                    leftMenu.classList.add('opened');
                }
            }
        }


        return <div id="cat-header" className="container-fluid fixed">
            <div className="menu main-menu">
                <div className="row">
                    <div className="col-xs-4 text-left"><a className="menu-toggle left" style={{ opacity: props.hidden ? "0" : "1" }} onClick={window.toggleLeftSideMenu} href="#left-menu"><div></div>
                        <div></div>
                        <div></div></a></div>

                    <div className="col-xs-4 text-center">Preturi Pentru Evaluari</div>
                    <div className="col-xs-4 text-right"><span></span></div>
                </div>
            </div>
        </div>
    }

    results(props) {
        var [results, setresults] = useState(props.results)
        const { promiseInProgress } = usePromiseTracker();

        useEffect(() => {
            setresults(props.results);
        }, [props])

            return <>
            <div id="search-feed" className="container-fluid" style={{overflowY:"scroll", height:"85vh"}}>
                <div className="ajax-load"><img alt="" src="/assets/img/spin.gif" /></div>           
                <div className="row search-feed" >
                {/* { results != "none" ? <h3>{date}</h3> : null} */}
                    {(() => { //var rez = results[0]?results[0]:[];

                        //afisarea celor mai relevante rezultate bazate pe nivelul de potrivire calculat pe server
                        /*   if(rez.sort)
                            rez = rez.sort((elm1,elm2)=>{return elm1.match_level < elm2.match_level}).reverse();
                          */
                        if (results !== "none" && results.length > 0) {
                            if (results[0].match_level) {
                                results = results.sort((elm1, elm2) => { return elm1.match_level < elm2.match_level }).reverse();
                            }

                            return results.map((value, index) => {
                                const data = value.date;
                                const months = ['Ianuarie', 'Februarie', 'Martie', 'Aprilie', 'Mai', 'Iunie', 'Iulie', 'August', 'Septembrie', 'Octombrie', 'Noiembrie', 'Decembrie'];
                                const resourceDate = new Date(data);
                                const formattedDate = months[resourceDate.getMonth()] + " " + resourceDate.getFullYear();
                                var setDisplayed = new Set();

                                if(value.origin === "indreptare")
                                { 
                                    value.results.sort((a, b) =>parseInt(a.numar) - parseInt(b.numar));
                                    return value.results.map((val, ind) => {
                                        var title = val.descriereFisa.replace(/"/g, "");
                                        if(title.split(" ")[0]==="Fisa") title = title.split(".")[2];
                                        return <div className="col-sm-12" key={Math.random()} >
                                            <div className="content" style={{ boxShadow: "0 0 15px -5px", marginBottom: "7px" }}>
                                                <Link to={{
                                                    pathname: `/Fisa`,
                                                    query: { id: val.idFisa, editie: val.numeEditie }
                                                }}>
                                                    <h4 className="title" style={{ width: '100%', textAlign: 'left' }}>Fisa {val.numar}</h4>
                                                    <p style={{ textAlign: 'left' }}>{title}</p>
                                                </Link>
                                            </div>
                                        </div>
                                    });
                                }


                                else if(value.origin === "buletine") {
                                        if(value.sku[1] === "l") {
                                        var title = "CATALOG DE PRETURI DE DEVIZ PENTRU REPARATII IN CONSTRUCTII";
                                        
                                        return <div className="col-sm-12" key={Math.random()} >
                                            <div className="content">

                                                <a data-toggle="collapse" href="#catalog" className="content col-sm-12" aria-expanded="true" style={{ boxShadow: "0 0 15px -5px", marginBottom: "7px" }}>
                                                    <span className="ico"></span><h4 className="title" style={{ width: '100%', textAlign: 'left' }}>{title}
                                                    <i className="fa fa-caret-down" aria-hidden="true" style={{float:"right"}}></i>
                                                    </h4>
                                                    
                                                </a></div>
                                                <div className="collapse" id="catalog" aria-expanded="true"  >
                                                {value.results?.map((val, ind) => { 
                                                    var avDate = value.date === undefined ? val.priceDate : value.date;

                                                    return <ul style={{ width: '100%', textAlign: 'left' }} key={Math.random()} >
                                                                { 
                                                                    val.idChapter < 33 ?  <Link to={{
                                                                        pathname: `/Resurse`,
                                                                        query: { id: val.idChapter, capitol: val.chapterTitle, 
                                                                            data: avDate, source:"catalog" }
                                                                    }}>
                                                                        <h5 className="title" style={{ width: '100%', textAlign: 'left' }}>{val.chapterTitle}</h5>
                                                                        </Link>
                                                                    : null
                                                                }
                                                            </ul>
                                                    })
                                                }
                                                </div>
                                                {value.results?.map((val, ind) => {
                                                        if(val.idChapter >= 33 )
                                                        {
                                                            var avDate = value.date === undefined ? val.priceDate : value.date;
                                                            return <div className="col-sm-12" key={Math.random()} >
                                                            <div className="content" style={{ boxShadow: "0 0 15px -5px", marginBottom: "7px" }}>
                                                                <Link to={{
                                                                    pathname: `/Resurse`,
                                                                    query: { id: val.idChapter, capitol: val.chapterTitle, 
                                                                        data: avDate, source: "anexe"}
                                                                }}>
                                                                    <h4 className="title" style={{ width: '100%', textAlign: 'left' }}>{val.chapterTitle}</h4>
                                                                </Link>
                                                            </div>
                                                        </div>
                                                        }
                                                    })
                                                }      
                                        </div>
                                    }
                                    else 
                                    { 
                                        
                                        var title = "CATALOG DE PRETURI DE DEVIZ PENTRU INSTALATII";
                                        if(value.results.length <= 5)
                                        {
                                            return value.results?.map((val, ind) => { 
                                                console.error("valoare la instalatii: ", val);
                                                var avDate = value.date === undefined ? val.priceDate : value.date;
                                                var sursa = val.idChapter >= 40 ? "anexe" : "catalog";

                                                return <div className="col-sm-12" key={Math.random()} >
                                                <div className="content" style={{ boxShadow: "0 0 15px -5px", marginBottom: "7px" }}>
                                                            { 
                                                                <Link to={{
                                                                    pathname: `/Resurse`,
                                                                    query: { id: val.idChapter, capitol: val.chapterTitle, 
                                                                        data: avDate, source:sursa }
                                                                }}>
                                                                    <h4 className="title" style={{ width: '100%', textAlign: 'left' }}>{val.chapterTitle}</h4>
                                                                    </Link>
                                                            }                                                           
                                                        </div>
                                                    </div>
                                                })
                                        }
                                        else return <div className="col-sm-12" key={Math.random()} >
                                            <div className="content">

                                                <a data-toggle="collapse" href="#catalog" className="content col-sm-12" aria-expanded="true" style={{ boxShadow: "0 0 15px -5px", marginBottom: "7px" }}>
                                                    <span className="ico"></span><h4 className="title" style={{ width: '100%', textAlign: 'left' }}>{title}
                                                    <i className="fa fa-caret-down" aria-hidden="true" style={{float:"right"}}></i>
                                                    </h4>
                                                    
                                                </a>
                                                </div>
                                                <div className="collapse" id="catalog" aria-expanded="true"  >
                                                {
                                                value.results?.sort((a, b) => a.chapterTitle.length - b.chapterTitle.length).map((val, ind) => { 
                                                    var avDate = value.date === undefined ? val.priceDate : value.date;
                                                    var capitol = val.chapterTitle;
                                                    var poz1 = capitol.toLowerCase().indexOf("instalaţii electrice");
                                                    var poz2 = capitol.toLowerCase().indexOf("canalizările şi alimentările");
                                                    if( poz1 !== -1)
                                                    {
                                                        capitol = capitol.substring(0, poz1) + "INSTALATII ELECTRICE" + capitol.substring(poz1 + 20);
                                                    }

                                                    if( poz2 !== -1)
                                                    {
                                                        capitol = capitol.substring(0, poz2) + "CANALIZARILE SI ALIMENTARILE" + capitol.substring(poz2 + 28);
                                                    }
                                                    
                                                    var sursa = val.idChapter >= 48 && val.idChapter <= 50 ? "anexe" : "catalog";

                                                    if(!setDisplayed.has(val.idChapter))
                                                    {
                                                        setDisplayed.add(val.idChapter);
                                                        return <ul style={{ width: '100%', textAlign: 'left' }} key={Math.random()} >
                                                                { 
                                                                    (val.idChapter >= 25 && val.idChapter <= 28) || val.idChapter == 48 || val.idChapter == 49 || val.idChapter == 50 ?  <Link to={{
                                                                        pathname: `/Resurse`,
                                                                        query: { id: val.idChapter, capitol: val.chapterTitle, 
                                                                            data: avDate, source:sursa }
                                                                    }}>
                                                                        <h5 className="title" style={{ width: '100%', textAlign: 'left' }}>{capitol}</h5>
                                                                        </Link>
                                                                    : null
                                                                }

                                                                
                                                            </ul>
                                                    }
                                                    else return null;
                                                    })
                                                }

                                                
                                                </div>

                                                <div className="content">

                                                <a data-toggle="collapse" href="#matSan" className="content col-sm-12" aria-expanded="true" style={{ boxShadow: "0 0 15px -5px", marginBottom: "7px" }}>
                                                    <span className="ico"></span><h4 className="title" style={{ width: '100%', textAlign: 'left' }}>MATERIALE SANITARE
                                                    <i className="fa fa-caret-down" aria-hidden="true" style={{float:"right"}}></i>
                                                    </h4>
                                                    
                                                </a>
                                                </div>
                                                <div className="collapse" id="matSan" aria-expanded="true"  >
                                                {value.results?.map((val, ind) => { 
                                                    var avDate = value.date === undefined ? val.priceDate : value.date;

                                                    return <ul style={{ width: '100%', textAlign: 'left' }} key={Math.random()} >
                                                                { 
                                                                    val.idChapter == 40 || val.idChapter == 44 ?  <Link to={{
                                                                        pathname: `/Resurse`,
                                                                        query: { id: val.idChapter, capitol: val.chapterTitle, 
                                                                            data: avDate, source:"anexe" }
                                                                    }}>
                                                                        <h5 className="title" style={{ width: '100%', textAlign: 'left' }}>{val.chapterTitle}</h5>
                                                                        </Link>
                                                                    : null
                                                                }
                                                            </ul>
                                                    })
                                                }

                                                
                                                </div>

                                                <div className="content">

                                                <a data-toggle="collapse" href="#matElec" className="content col-sm-12" aria-expanded="true" style={{ boxShadow: "0 0 15px -5px", marginBottom: "7px" }}>
                                                    <span className="ico"></span><h4 className="title" style={{ width: '100%', textAlign: 'left' }}>MATERIALE ELECTRICE
                                                    <i className="fa fa-caret-down" aria-hidden="true" style={{float:"right"}}></i>
                                                    </h4>
                                                    
                                                </a>
                                                </div>
                                                <div className="collapse" id="matElec" aria-expanded="true"  >
                                                {value.results?.map((val, ind) => { 
                                                    var avDate = value.date === undefined ? val.priceDate : value.date;

                                                    return <ul style={{ width: '100%', textAlign: 'left' }} key={Math.random()} >
                                                                { 
                                                                    val.idChapter == 45 || val.idChapter == 46 ?  <Link to={{
                                                                        pathname: `/Resurse`,
                                                                        query: { id: val.idChapter, capitol: val.chapterTitle, 
                                                                            data: avDate, source:"anexe" }
                                                                    }}>
                                                                        <h5 className="title" style={{ width: '100%', textAlign: 'left' }}>{val.chapterTitle}</h5>
                                                                        </Link>
                                                                    : null
                                                                }
                                                            </ul>
                                                    })
                                                }

                                                
                                                </div>

                                                <div className="content">

                                                <a data-toggle="collapse" href="#matInc" className="content col-sm-12" aria-expanded="true" style={{ boxShadow: "0 0 15px -5px", marginBottom: "7px" }}>
                                                    <span className="ico"></span><h4 className="title" style={{ width: '100%', textAlign: 'left' }}>MATERIALE INCALZIRE
                                                    <i className="fa fa-caret-down" aria-hidden="true" style={{float:"right"}}></i>
                                                    </h4>
                                                    
                                                </a>
                                                </div>
                                                <div className="collapse" id="matInc" aria-expanded="true"  >
                                                {value.results?.map((val, ind) => { 
                                                    var avDate = value.date === undefined ? val.priceDate : value.date;

                                                    return <ul style={{ width: '100%', textAlign: 'left' }} key={Math.random()} >
                                                                { 
                                                                    val.idChapter == 47 || val.idChapter >= 51 && val.idChapter <= 95  ?  <Link to={{
                                                                        pathname: `/Resurse`,
                                                                        query: { id: val.idChapter, capitol: val.chapterTitle, 
                                                                            data: avDate, source:"anexe" }
                                                                    }}>
                                                                        <h5 className="title" style={{ width: '100%', textAlign: 'left' }}>{val.chapterTitle}</h5>
                                                                        </Link>
                                                                    : null
                                                                }
                                                            </ul>
                                                    })
                                                }

                                                
                                                </div>

                                                
                                                
                                                {value.results?.map((val, ind) => {
                                                        if(val.idChapter >= 33 && val.idChapter !== 45 && val.idChapter !== 46 && val.idChapter !== 40 && val.idChapter !== 44  && val.idChapter <47)
                                                        {
                                                            var avDate = value.date === undefined ? val.priceDate : value.date;
                                                            return <div className="col-sm-12" key={Math.random()} >
                                                            <div className="content" style={{ boxShadow: "0 0 15px -5px", marginBottom: "7px" }}>
                                                                <Link to={{
                                                                    pathname: `/Resurse`,
                                                                    query: { id: val.idChapter, capitol: val.chapterTitle, 
                                                                        data: avDate, source: "anexe"}
                                                                }}>
                                                                    <h4 className="title" style={{ width: '100%', textAlign: 'left' }}>{val.chapterTitle}</h4>
                                                                </Link>
                                                            </div>
                                                        </div>
                                                        }
                                                    })
                                                }      
                                        </div>
                                    } 
                                        
                                }

                            else{
                                    if(value.origin.includes("indreptare")){
                                    value.results.sort((a, b) =>parseInt(a.numar) - parseInt(b.numar));
                                    return value.results.map((val, ind) => {
                                        
                                        var title = val.descriereFisa.replace(/"/g, "");
                                        if(title.split(" ")[0]==="Fisa") title = title.split(".")[2];
                                        return <div className="col-sm-12" key={Math.random()} >
                                            <div className="content" style={{ boxShadow: "0 0 15px -5px", marginBottom: "7px" }}>
                                                <Link to={{
                                                    pathname: `/Fisa`,
                                                    query: { id: val.idFisa, editie: val.numeEditie }
                                                }}>
                                                    <h4 className="title" style={{ width: '100%', textAlign: 'left' }}>{val.numeEditie + " > Fisa " + val.numar}</h4>
                                                    <p style={{ textAlign: 'left' }}>{title}</p>
                                                </Link>
                                            </div>
                                        </div>
                                    });} 

                                    else {return value.results.map((val, ind) => { var source = val.idChapter >= 33 ? "anexe" : "catalog";
                                    var avDate = value.date === undefined ? val.priceDate : value.date;
                                    const months = ['Ianuarie', 'Februarie', 'Martie', 'Aprilie', 'Mai', 'Iunie', 'Iulie', 'August', 'Septembrie', 'Octombrie', 'Noiembrie', 'Decembrie'];
                                    const resource = new Date(avDate);
                                    const formattedDate = months[resource.getMonth()] + " " + resource.getFullYear();
                                    return <div className="col-sm-12" key={Math.random()} >
                                                <div className="content" style={{ boxShadow: "0 0 15px -5px", marginBottom: "7px" }}>
                                                    <Link to={{
                                                        pathname: `/Resurse`,
                                                        query: { id: val.idChapter, capitol: val.chapterTitle, 
                                                        data: avDate, source: source}
                                                    }}>
                                                        <h4 className="title" style={{ width: '100%', textAlign: 'left' }}>{formattedDate + " > " + val.chapterTitle}</h4>
                                                    </Link>
                                                </div>
                                            </div> } )} }                               
                        })
                        }
                        else if (results !== "none")
                            return <></>
                        else
                            return <h4 style={{ textAlign: "center" }}>Nu a fost gasit continutul cautat!</h4>
                    })()}
                </div>

                {
                    promiseInProgress && (<div className='loading' style={{marginTop:"18%"}}>
                    <LoadingIndicator/>
                </div>)
                }    
            </div> </>
    }

}


export default FunctionalComponent