import React from 'react';

function successRegistration(props) {
    return(
        <div>
            <h2 style={{color:"white", textAlign:"center"}}>PENTRU A VA ACTIVA CONTUL VA RUGAM SA ACCESATI LINK-UL PRIMIT PE EMAIL</h2>
            <a href='/Login' style={{cursor:"pointer"}} className="btn btn-lg btn-block btn-transparent">INAPOI LA LOGIN</a>
        </div>
    );
}

export default successRegistration