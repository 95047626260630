import React from 'react';
import { usePromiseTracker } from 'react-promise-tracker';
import ReactLoading from 'react-loading';

const LoadingIndicator = () => {
  const { promiseInProgress } = usePromiseTracker();

  return (
    promiseInProgress && (
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <ReactLoading type="spinningBubbles" color="#112D4E" />
      </div>
    )
  );
};

export default LoadingIndicator;
