import React from 'react';
import ClipLoader from "react-spinners/ClipLoader";

function Form(props) {
    return (

        <form style={{ display: props.displayForm }}>
            <h3 style={{ display: props.displayRegMsg, textAlign: 'center', color: "white" }}>Pentru a accesa varianta demo este necesar sa va creati un cont</h3>

            <div className="form-group ico">
                <input onFocus={props.onFocus} type="text" id="email" className="form-control" placeholder="Email" />
                <i className="fa fa-user ico"></i>
            </div>
            <div className="form-group ico">
                <input onFocus={props.onFocus} type="password" id="password" className="form-control" placeholder="Parola" />
                <i className="fa fa-lock ico"></i>
            </div>
            <div className="form-group ico" style={{ display: props.retypePassword }}>
                <input type="password" id="passwordRe" className="form-control" placeholder="Repeta Parola" />
                <i className="fa fa-lock ico"></i>
            </div>
            <div className="form-group">
                <a onClick={props.onClick} style={{ cursor: "pointer" }} className="btn btn-lg btn-block w-50 btn-transparent">{props.principalButton}</a>
            </div>
            <div className="form-group text-center hidden" id="spinner">
                <ClipLoader color={'white'} loading={true} size={64} />
            </div>
            <div style={{ display: "flex" }}>
                <a onClick={props.handleSetAction} style={{ cursor: "pointer", height: "100%", marginTop: 0, marginRight: "10px" }} className="btn btn-lg btn-block btn-transparent">{props.secondaryButton}</a>
                <a onClick={props.forgotPassword} style={{ cursor: "pointer", height: "100%", marginTop: 0 }} className="btn btn-lg btn-block btn-transparent">{props.resetButton}</a>

            </div>
            <div className="form-group">
                <a href="https://www.matrixrom.ro/produs/preturi-pentru-evaluari/" target="_blank" style={{ cursor: "pointer" }} className="btn btn-lg btn-block w-50 btn-transparent"> CUMPARA</a>
            </div>
            <div className="form-group">
                <div id="warn" className="hidden alert alert-warning" role="alert">
                    <button type="button" className="close" data-dismiss="alert" aria-label="Close"><span aria-hidden="true">&times;</span></button>
                    Date de autentificare neacceptate!
                </div>

                <div id="warn-devs" className="hidden alert alert-warning" role="alert">
                    <button type="button" className="close" data-dismiss="alert" aria-label="Close"><span aria-hidden="true">&times;</span></button>
                    S-a depasit numarul de logari disponibile. Contactati Matrixrom!
                </div>
            </div>
        </form>

    );
}

export default Form;