import React from "react";
import ClipLoader from "react-spinners/ClipLoader";

function Form(props) {
	return (
		<form style={{ display: props.displayForm }}>
			<div className="form-group ico">
				<input
					onFocus={props.onFocus}
					type="text"
					id="email"
					className="form-control"
					placeholder="Email"
				/>
				<i className="fa fa-user ico"></i>
			</div>
			<div className="form-group">
				<a
					onClick={props.sendResetLink}
					style={{ cursor: "pointer" }}
					className="btn btn-lg btn-block w-50 btn-transparent"
				>
					{props.principalButton}
				</a>
			</div>
			<div className="form-group text-center hidden" id="spinner">
				<ClipLoader color={'white'} loading={true} size={64} />
			</div>
			<div className="form-group">
				<a
					onClick={props.forgotPassword}
					style={{ cursor: "pointer" }}
					className="btn btn-lg btn-block w-50 btn-transparent"
				>
					{props.resetButton}
				</a>
			</div>
			<div className="form-group">
				<div
					id="resetPassword"
					className="hidden alert alert-warning"
					role="alert"
				>
					<button
						type="button"
						className="close"
						data-dismiss="alert"
						aria-label="Close"
					>
						<span aria-hidden="true">&times;</span>
					</button>
					V-am trimis un mail pentru resetarea parolei.
				</div>

				<div
					id="resetPassword-error"
					className="hidden alert alert-warning"
					role="alert"
				>
					<button
						type="button"
						className="close"
						data-dismiss="alert"
						aria-label="Close"
					>
						<span aria-hidden="true">&times;</span>
					</button>
					Mail-ul nu a putut fi trimis din cauza unei erori interne.
				</div>
			</div>
		</form>
	);
}

export default Form;
