import React from 'react'
import '../App.css'
import FunctionalComponents from '../FunctionalComponents'
import App from './../App'
import printJS from 'print-js'
import { ScrollMenu } from 'react-horizontal-scrolling-menu';
import ReactLoading from "react-loading";
import { usePromiseTracker, trackPromise } from "react-promise-tracker";


var resizebase64 = require('resize-base64');

const LoadingIndicator = props => {
  const { promiseInProgress } = usePromiseTracker();

  return ( promiseInProgress &&
    <div display="flex" justify-content="center">
        <ReactLoading type="spinningBubbles" color="#112D4E" />
    </div> 
);  
}

class Resurse extends React.Component {

  
  
  constructor(props) {
    super()
    this.state = { data: '', lines: [] }
  }



  componentDidMount() {
    function watermarkedDataURL(canvas) {
      var tempCanvas = document.createElement('canvas')
      var tempCtx = tempCanvas.getContext('2d')
      var cw, ch
      cw = tempCanvas.width = canvas.width
      ch = tempCanvas.height = canvas.height

      var img = new Image()
      img.src = 'assets/img/matrixrom.png'
      tempCanvas.width = 2 * img.height
      tempCanvas.width = 2 * img.width
      tempCtx.filter = 'brightness(10) grayscale(1) opacity(0.35)'
      //         tempCtx.globalAlpha = .50;

      tempCtx.rotate((-45 * Math.PI) / 180)
      tempCtx.translate(img.height, img.width)
      tempCtx.drawImage(img, -img.width, -img.height, 300, 100)

      // just testing by adding tempCanvas to document

      return tempCanvas.toDataURL()
    }

    var payload = {
      id: this.props.location.query ? this.props.location.query.id : -1,
      data: this.props.location.query ? this.props.location.query.data : -1,
      source: this.props.location.query ? this.props.location.query.source : -1,
      userId: App.tryJSONParse(localStorage['user']).id,
      token: App.tryJSONParse(localStorage['user']).token,
    }

    if (payload.id === undefined || payload.id <= 0) {
      this.props.history.push('/')
    }

    if (localStorage.user) {
      var user = App.tryJSONParse(localStorage['user'])
    //   try {
    //     window.gtag('event', 'accesare_fisa', {
    //       'User-ID': user.user_id,
    //       Fisa: payload.id,
    //     })
    //     window.fbq('trackCustom', 'accesare_fisa', {
    //       'User-ID': user.user_id,
    //       Fisa: payload.id,
    //     })
    //   } catch (e) {
    //     console.warn(e)
    //   }

    //   setTimeout(
    //     function () {
    //       try {
    //         window.gtag('event', 'fisa_citita', {
    //           'User-ID': user.user_id,
    //           Fisa: payload.id,
    //         })
    //         window.fbq('trackCustom', 'fisa_citita', {
    //           'User-ID': user.user_id,
    //           Fisa: payload.id,
    //         })
    //       } catch (e) {
    //         console.warn(e)
    //       }
    //     }.bind(user, payload),
    //     30 * 100,
    //   )

      if(payload.source == "anexe") var path = '/openResurse';
      else var path = '/openCatalog';

      payload.id !== -1 && trackPromise(
      fetch(window.API_URL + path, {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'plain/text',
        },

        body: JSON.stringify(payload),
      })).then((response) => {
        response.json().then((jsn) => {
          var c = document.getElementById('myCanvas')
          if(jsn.width_factor > 0){
            if(jsn.width_factor <= 50)
              c.width = jsn.width_factor*18
            else if(jsn.width_factor > 50 && jsn.width_factor <= 70) //65
              c.width = jsn.width_factor*14.5
            else if(jsn.width_factor > 70 && jsn.width_factor <= 90)
              c.width = jsn.width_factor*12
            else if(jsn.width_factor > 90 && jsn.width_factor <= 100)
              c.width = jsn.width_factor*12
            else if (jsn.width_factor > 100 && jsn.width_factor <= 110)
              c.width = jsn.width_factor*11
            else if (jsn.width_factor > 110 && jsn.width_factor <= 120)
              c.width = jsn.width_factor*10.5 //112
            else if (jsn.width_factor > 120 && jsn.width_factor <= 130)
              c.width = jsn.width_factor*10.5 // 130
            else if (jsn.width_factor > 130 && jsn.width_factor <= 140)
              c.width = jsn.width_factor*10 // 135
            else if (jsn.width_factor > 140 && jsn.width_factor <= 150)
              c.width = jsn.width_factor*9.5 // 142
            else if(jsn.width_factor > 150 && jsn.width_factor <= 170)
              c.width = jsn.width_factor*9.5
            else if(jsn.width_factor > 170 && jsn.width_factor <= 181)
              c.width = jsn.width_factor*9.1 // 181
            else if(jsn.width_factor > 181 && jsn.width_factor <= 200)
              c.width = jsn.width_factor*8.7 // 184
            else if(jsn.width_factor > 200 && jsn.width_factor <= 210)
              c.width = jsn.width_factor*8
            else if(jsn.width_factor > 210 && jsn.width_factor <= 220)
              c.width = jsn.width_factor*7.5
            else if (jsn.width_factor == 236 && jsn.height_factor < 10) 
              c.width = c.width = jsn.width_factor*6.6
            else if (jsn.width_factor == 236 && jsn.height_factor >= 10) 
              c.width = c.width = jsn.width_factor*7.2
            else if(jsn.width_factor > 220 && jsn.width_factor <= 240)
              c.width = jsn.width_factor*7 // 236
            else if(jsn.width_factor > 240 && jsn.width_factor <= 260)
              c.width = jsn.width_factor*6.8
            else if(jsn.width_factor > 260 && jsn.width_factor <= 300)
              c.width = jsn.width_factor*6.3
            else if(jsn.width_factor > 300)
              c.width = jsn.width_factor*7
            else
              c.width = jsn.width_factor*7.2
          }
          //if(jsn.width_factor <= 90) c.width = 1700
          if(jsn.height_factor === 1) c.height = 36.5*jsn.height_factor
          if(jsn.height_factor >= 600 ) c.height = 37.2*jsn.height_factor
          else if(jsn.height_factor >= 500 ) c.height = 40.1*jsn.height_factor
          else if(jsn.height_factor >= 300 && jsn.width_factor >= 200) c.height = 31.5*jsn.height_factor
          else if(jsn.height_factor >= 300 && jsn.width_factor >= 100) c.height = 36.3*jsn.height_factor
          else if(jsn.height_factor >= 300 && jsn.width_factor < 100) c.height = 40.5*jsn.height_factor
          else if(jsn.height_factor < 300 && jsn.height_factor >= 220) c.height = 40.5*jsn.height_factor
          else if(jsn.height_factor < 220 && jsn.height_factor >= 200) c.height = 35.5*jsn.height_factor
          else if(jsn.height_factor < 200 && jsn.height_factor > 160) c.height = 31.5*jsn.height_factor
          else if(jsn.height_factor <= 160 && jsn.height_factor > 100 && jsn.width_factor >= 200) c.height = 34*jsn.height_factor
          else if(jsn.height_factor <= 160 && jsn.height_factor > 100 && jsn.width_factor >= 140) c.height = 36.5*jsn.height_factor
          else if(jsn.height_factor <= 160 && jsn.height_factor > 100 && jsn.width_factor < 140) c.height = 40.5*jsn.height_factor
          else if(jsn.height_factor <= 100 && jsn.height_factor > 70) c.height = 38*jsn.height_factor
          else if(jsn.height_factor <= 70 && jsn.height_factor > 60) c.height = 35*jsn.height_factor
          else if(jsn.height_factor <= 60 && jsn.height_factor > 50) c.height = 40*jsn.height_factor
          else if(jsn.height_factor >= 30 && jsn.height_factor <= 50 && jsn.width_factor >= 100) c.height = 36.8*jsn.height_factor // 45
          else if(jsn.height_factor >= 30 && jsn.height_factor <= 50 && jsn.width_factor < 100) c.height = 42*jsn.height_factor
          else if(jsn.height_factor < 30 && jsn.height_factor > 27) c.height = 80*jsn.height_factor
          else if(jsn.height_factor <= 27 && jsn.height_factor >= 9) c.height = 44*jsn.height_factor // 25
          else c.height = 80*jsn.height_factor
          var ctx = c.getContext('2d')
          var img = new Image()
          var img2 = new Image()
          img.crossOrigin = 'anonymous'
          img.onload = () => {
            //ctx.drawImage(img2, c.width / 2 - 280, c.height / 2 - 200)
            ctx.drawImage(img, 15, 10)
            //console.error('Image loaded successfully');
          }

          var dataURL = watermarkedDataURL(c)

          //console.error("aici e json content: ", jsn.content);
          img2.src = dataURL
          
          img.src = jsn.content

          // img.onerror = () => {
          //   console.error('Image failed to load:', img.src);
            
          // };
          
          
          // img2.onerror = () => {
          //   console.error('Image2 failed to load:', img2.src);
          // };
          

          document.getElementById('printButt').onclick = function () {
            /*   document.querySelector('style').textContent += "@media print{  * { visibility: visible; } #myCanvas {position: absolute; top: 0;left: 0;visibility: visibile; }}"
                       window.print();  */

            fetch(window.API_URL + '/print', {
              method: 'POST',
              headers: {
                // 'Content-Type': 'application/json',
                Accept: 'application/json',
              },
              body: JSON.stringify(payload),
            }).then((response) => {
              response.json().then((res) => {
                var prints = res.prints
                console.log(prints)
                if (prints > 0) {
                  console.log('res;')

                  if (res.success === true) {
                    var resizedImage = c.toDataURL()
                    var title = document.getElementById("title").innerHTML
                    var fileDate = document.getElementById("fileDate").innerHTML
                    var date = fileDate.split(" ")[2] + " " + fileDate.split(" ")[3]
                    printJS({printable: resizedImage, type: 'image', imageStyle : 'width: auto; height: auto; display:flex;justify-content:center;text-align:center;', documentTitle:title + " - " + date, targetStyles:['*']})
                  } else {
                    alert(`A aparut o eroare la printare`)
                  }
                } else {
                  alert(
                    `Cota de printare a fost epuizata! Puteti achizitiona dreputuri suplimentare de printare de pe site-ul https://www.matrixrom.ro/`,
                  )
                  window.open('https://matrixrom.ro/', '_blank')
                }
              })
            })
          }
          //Event dupa printare, dar nu spune daca a printat pagina sau nu, doar daca a deschis o in the first place
          window.addEventListener('afterprint', (event) => {
            console.log('After print')
          })
          this.forceUpdate()
        })
      })
    }
  }

  componentWillUnmount() {
    document.getElementsByTagName('html')[0].style.minWidth = '100%'
    document.getElementsByTagName('html')[0].style.width = 'auto'
  }

  render() {
    var FCP = new FunctionalComponents()
    const months = ['Ianuarie', 'Februarie', 'Martie', 'Aprilie', 'Mai', 'Iunie', 'Iulie', 'August', 'Septembrie', 'Octombrie', 'Noiembrie', 'Decembrie'];
    const resourceDate = this.props.location.query ? this.props.location.query.data : '';
    const resource = new Date(resourceDate);
    const formattedDate = months[resource.getMonth()] + " " + resource.getFullYear();
    const getCanvas = document.getElementById("myCanvas");
    

    return (
      <>
      
        <FCP.navbar locked={true} hidden={true} />
        <FCP.sidebar editions={window.editions} opened={false} />
        <FCP.search_header
          backbutton={true}
          edts={['Editia Aprilie 2020', 'Editia Iunie 2020']}
          construction_types={['Constructii Uzuale', 'Alte Constructii']}
        />

        <div className="App">
          <div style={{
                alignItems: 'center',
                display: 'flex',
                justifyContent: 'center',
          }} id="headingContainer">
            <h2 style={{
              width: '80%',
              marginLeft: '75px',
            }} id="title">
              {this.props.location.query ? this.props.location.query.capitol : ''}
            </h2>
            <button
            className="btn btn-default"
            id="printButt"
            style={{
              float: 'right',
              marginTop: '10px',
              border: '1px solid black',
              background: 'white',
            }}
            >
            {' '}
              <i className="fa fa-print"></i> Print{' '}
            </button>
          </div>
          
          <div style={{
            display: 'flex',
            justifyContent: 'center',
          }}>
            <h3 className="resource" id="fileDate">
              Editia din {formattedDate}
            </h3>
          </div>

          <div style={{display:"flex", justifyContent:"center", alignItems:"center"}}>
            <LoadingIndicator/>
          </div>
          
          <div id="canvas-wrapper"
            >
          <p align="center"
                    display="flex"
                    justify-content="center"
                    style={{marginBottom:'0px'}}>
                    
                      <ScrollMenu>
                      
                      {/* <div class="scrollmenu"> */}
                      <canvas
                        onContextMenu={(e) => e.preventDefault()}
                        id="myCanvas"
                        className='scrollable-content'
                        height="1500px"
                      >
                      </canvas>
                      
                      {/* </div> */}
                      
                      </ScrollMenu>
              </p>
            </div> 
        </div>
      </>
    )
  }
}

export default Resurse
