import React from 'react';
import '../App.css';
import '../Home.css';

import FunctionalComponents from '../FunctionalComponents';

class Search extends React.Component {

    

    render() {
        var FCP = new FunctionalComponents();
        window.editions = window.editions ? window.editions : [];
        window.results = window.results ? window.results : [];


        return <>
            <FCP.navbar />
            <FCP.sidebar editions={window.editions} opened={false} />
            <FCP.search_header edts={['Aprilie 2020', 'Iunie 2020','Iulie 2020']} construction_types={['Cladiri de locuit','Alte tipuri de cladiri']} />
            <FCP.results results={window.results} />
        </>
    }



    componentDidMount() {



    }
}

export default Search;

